import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading, P, Span } from "../../../packages/Typography/src";
import DataStoryCard from "../../../packages/DataStoryCard/src";
import Link from "../../../packages/Link/src";
import Icon from "../../../packages/Icon/src";
import { DATASTORIES } from "../../constants/patterns";

var FeaturedTopicPattern = function FeaturedTopicPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    size: "md",
    py: "5rem"
  }, React.createElement(Grid, {
    alignItems: "center"
  }, React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 6"]
  }, React.createElement(Span, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Featured topic"), React.createElement(Heading, {
    as: "h2",
    mt: "0.75rem"
  }, "Topic title"), React.createElement(P, {
    size: "md",
    muted: true
  }, "This is a short introductory text for this topic. You can explore two sample apps from here. Alternatively you can just continue directly to the topic page, where you will find all apps and stories."), React.createElement(Link, {
    variant: "standalone",
    href: "/"
  }, "See topic", React.createElement(Icon, {
    name: "chevron_right",
    ml: "0.5rem",
    size: "1.25rem"
  }))), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 6"]
  }, React.createElement(Grid, null, DATASTORIES.slice(0, 2).map(function (_ref) {
    var id = _ref.id,
        restProps = _objectWithoutPropertiesLoose(_ref, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", "span 6"]
    }, React.createElement(DataStoryCard, restProps));
  })))))));
};

export default FeaturedTopicPattern;